<template>
  <div class="order-detail">
    <content-block title="基本信息">
      <el-descriptions :column="3" size="normal" :labelStyle="{minWidth: '4.5em'}">
        <el-descriptions-item label="客户抬头">{{
          detailInfo.unit | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="订单状态">{{
          detailInfo.orderStsName | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="客户联系人">{{
          detailInfo.salesmanName | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="联系人电话">{{
          detailInfo.salesmanPhone | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="是否含税">{{
          detailInfo.isTaxName | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="联系人邮箱">{{
          detailInfo.email | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{
          detailInfo.createTime | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="收货信息" :span='2'>
          <span :title="detailInfo.detailAddress">{{
            detailInfo.detailAddress | setEmpty
          }}</span></el-descriptions-item
        >
        <el-descriptions-item label="备注">{{
          detailInfo.remark | setEmpty
        }}</el-descriptions-item>
      </el-descriptions>
    </content-block>
    <content-block title="物资信息">
      <vxe-grid
        ref="xGrid"
        auto-resize
        resizable
        border
        align="center"
        highlight-current-row
        highlight-hover-row
        show-overflow
        show-footer
        :columns="columns"
        :data="detailInfo.orderMaterialRelationRepDtoList"
        :footer-method="footerMethod"
        :merge-footer-items="footerItems"
      >
        <template slot="empty">
          <el-empty :image-size="100" description="暂无数据"></el-empty>
        </template>
      </vxe-grid>
    </content-block>
    <content-block title="日志信息">
      <TableWithPagination :columnList="logColumns" :tableData="logData" />
    </content-block>
  </div>
</template>
<script>
import TableWithPagination, {
  TablePaginationMixin,
} from '@/components/common/TableWithPagination';
import { queryDetail } from '@/api/pickUpOrders';
import setTableCols from './configs';
import { logTypes } from '@/model/logTypes';
import { queryLogListById } from '@/api/saleContract';

export default {
  name: 'sale_contract_detail',
  mixins: [TablePaginationMixin],
  components: {
    TableWithPagination,
  },
  props: {
    orderCode: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  filters: {
    setEmpty (val) {
      return val ? val : '--';
    },
  },
  data () {
    return {
      detailInfo: {
        orderMaterialRelationRepDtoList: [],
      },
      columns: setTableCols.call(this, 'detail'),
      logData: [],
      logColumns: [
        {
          prop: 'createTime',
          label: '时间',
          tooltip: true,
        },
        {
          prop: 'logType',
          label: '事件类型',
          tooltip: true,
          render: (h, { row }) => {
            let val = '--';

            for (let v of logTypes) {
              if (v.value === row.logType) {
                val = v.label;
                break;
              }
            }

            return h('span', val);
          },
        },
        {
          prop: 'content',
          label: '日志内容',
          tooltip: true,
        },
      ],
      footerItems: [{ row: 0, col: 1, rowspan: 1, colspan: 10 }],
    };
  },
  created () {
    this.getDetail();
    this.getLog();
  },
  methods: {
    getDetail () {
      queryDetail({ orderCode: this.orderCode }).then(({ body }) => {
        body.detailAddress
          = `${body.receivingName || ''}-${body.receivingPhone || ''}-${
            body.receivingProvinceName || ''
          }${body.receivingCityName || ''}${body.receivingAreaName || ''}${
            body.receivingAddrs || ''
          }` || '--';
        body.isTaxName = body.isTax === 1 ? '是' : '否';
        body.orderStsName
          = body.orderSts === 1
            ? '待审核'
            : body.orderSts === 2
              ? '执行中'
              : body.orderSts === 3
                ? '已驳回'
                : body.orderSts === 4
                  ? '已取消'
                  : '已完成';

        this.detailInfo = body;
      });
    },
    getLog () {
      queryLogListById({
        id: this.id,
      }).then(({ body }) => {
        this.logData = body;
      });
    },
    footerMethod ({ data }) {
      if (!data.length) {
        return [];
      }

      let totalAmount = 0;
      let totalPrice = 0;

      data.forEach((v) => {
        totalAmount += Number(v.currentBillingNum);
        totalPrice += Number(v.unitPrice * 100 * v.currentBillingNum);
      });

      return [['合计', `数量：${totalAmount}, 总金额：${totalPrice / 100}`]];
    },
  },
};
</script>
<style lang="scss" scoped>
.order-detail {
  .common-font {
    color: #909399;
    font-size: 14px;
    font-weight: 500;
  }

  .annex {
    margin-right: 1em;
  }

  /deep/ .el-descriptions-item__content {
    overflow: hidden;
    word-break: keep-all;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
